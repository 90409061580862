import {EChartOption} from 'echarts/lib/echarts';
import {GranularityCubeJs} from 'src/app/enums/cubejs/granularity';
import {NumberRank} from 'src/app/enums/number/number-rank.enum';
import {StyleNumber} from 'src/app/enums/number/StyleNumber.enum';
import {ApplicationFeature} from 'src/swagger-gen/web/models';
import {routeEnvironment} from './route.environment';

const chartTitleFontStyle: EChartOption.BaseTextStyle['fontStyle'] = 'normal';
const chartTitleFontWeight: EChartOption.BaseTextStyle['fontWeight'] = 'normal';
const font = 'Open Sans';

export const coreEnvironment = {
    // google Map
    mapIcon: '/assets/images/energies/electricity.svg',
    googleMapKey: 'AIzaSyALn8LlQgSiiZWZTr_QqwdVdKgYWaFpFxM',
    defaultLatitude: 45.95114969,
    defaultLongitude: 2.41699219,
    defaultZoom: 6,
    defaultFullscreenControl: false,
    defaultMapTypeControl: false,
    // maintenance
    maintenance: false,
    noData: '--',
    showSymbol: false,
    // main
    title: 'HYDROWATT',
    logoPath: 'assets/images/logo-hydrowatt-white.svg',
    logoInline: 'assets/images/logo-hydrowatt-white.svg',

    // Whitelist Auhtorized Features
    whitelistAuthorizedFeatures: [ApplicationFeature.SITE_OPERATION, ApplicationFeature.SITE_MANAGEMENT, ApplicationFeature.USER],
    // Features Usage
    useSubscriptionsFeature: false,
    actionOnAlarms: true,
    formulaAvailability: false,
    // public site
    publicSiteDisplay: false,


    // main routes : relative url, name, icon (class name)
    routes: routeEnvironment,

    // font
    mainFont: font,

    // ====================================
    //              TABLE
    // ====================================
    TABLE_NO_DATA_MESSAGE: 'Aucun résultat',

    // ====================================
    //              CHART
    // ====================================
    // title
    CHART_TITLE_BACKGROUND_COLOR: 'transparent',
    CHART_TITLE_HORIZONTAL_ALIGN: 'center',
    CHART_TITLE_BORDER_COLOR: 'transparent',
    CHART_TITLE_BORDER_WIDTH: 1,
    CHART_TITLE_BORDER_RADIUS: 5,
    CHART_TITLE_PADDING: 0,
    CHART_TITLE_FONT_STYLE: chartTitleFontStyle,
    CHART_TITLE_FONT_WEIGHT: chartTitleFontWeight,
    CHART_TITLE_FONT_FAMILY: font,
    CHART_TITLE_FONT_SIZE: 18,
    // dataZoom slider
    CHART_ZOOM_BORDER_COLOR: 'transparent',
    CHART_ZOOM_BACKGROUND_COLOR: '#d5dbe4',
    CHART_ZOOM_HANDLE_THICKNESS: 30,
    CHART_ZOOM_HANDLE_SIZE: 30,
    CHART_ZOOM_HANDLE_STYLE_SHADOW_BLUR: 0,
    CHART_ZOOM_HANDLE_STYLE_SHADOW_X: 0,
    CHART_ZOOM_HANDLE_STYLE_SHADOW_Y: 0,
    CHART_ZOOM_HANDLE_STYLE_SHADOW_COLOR: 'transparent',
    CHART_ZOOM_HANDLE_STYLE_OPACITY: 1,
    // pie chart
    PIE_CHART_SIZE: 75,
    PIE_CHART_LEGEND_BREAKPOINT: 1500,

    // cube js + other env
    stationGranularityBlackList: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE, GranularityCubeJs.QUARTER],
    granularityBlackList: {
        customRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE],
        dayRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE, GranularityCubeJs.DAY, GranularityCubeJs.MONTH, GranularityCubeJs.QUARTER, GranularityCubeJs.WEEK, GranularityCubeJs.YEAR],
        weekRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE, GranularityCubeJs.WEEK, GranularityCubeJs.MONTH, GranularityCubeJs.QUARTER, GranularityCubeJs.YEAR],
        monthRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE, GranularityCubeJs.HOUR, GranularityCubeJs.WEEK, GranularityCubeJs.MONTH, GranularityCubeJs.QUARTER, GranularityCubeJs.YEAR],
        quarterRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE, GranularityCubeJs.HOUR, GranularityCubeJs.QUARTER, GranularityCubeJs.YEAR],
        yearRange: [GranularityCubeJs.SECOND, GranularityCubeJs.MINUTE, GranularityCubeJs.HOUR, GranularityCubeJs.QUARTER, GranularityCubeJs.YEAR]
    },

    // dates times format (moment)
    datetimeFormat: {
        datetime: 'L LTS',
        dateHourMinute: 'L LT',
        dateHour: 'L HH[h]',
        dayDate: 'ddd L',
        shortMonth: 'MMM',
        year: 'YYYY'
    },
    // for date picker library : date (2021-12-31)
    datePickerFormat: {
        date: 'DD/MM/YYYY',
        dateTime: 'DD/MM/YYYY HH:mm:ss'
    },
    timeRefreshIndicators: 60000, // milliseconds
    images: {
        badge: '/assets/images/badge.svg'
    },
    data: {
        defaultStyle: StyleNumber.DECIMAL,
        defaultRank: NumberRank.UNIT,
        fixedPercent: 1,
        fixedCurrency: 0,
        fixedDecimal: 2,
        fixedDuration: 2
    },
    regexp: {
        formattedDecimals: /^(((\d+\s\d+)+|\d+)((\.|,)\d+)?)$/,
        decimals: /^(\d+((\.|,)\d+)?)$/,
        numbers: /^\d+$/,
        thousands: /^(\d{0,2}([,.|\s]))?\d{0,3}$/
    },
    colors: {
        base: '#20518c',
        info: "#808080",
        danger: "#ff0000",
        success: "#155724",
        white: "#fff",
        offWhite: "#f1f1f1"
    }
};
