export enum RouteId {
    // root
    DASHBOARD,
    EMS,
    DASHBOARD_GNL,
    MAP,
    REGIONS,
    STATIONS,
    GRAPHS,
    SUBSCRIPTIONS,
    BADGES,
    USERS,
    VEHICLES,
    ADMINISTRATION,
    ADMINISTRATION_DETAILS,
    DETAILS,
    DIFFUSION_LIST,
    ROLES,
    ACQUISITION_UNIT,
    EXPORT,
    TICKETS,
    DASHDEFAULT,
    DRAWGRAPHS,
    ALARMS,

    // station IDs
    STATION_DASHBOARD,
    STATION_DETAIL, 
    STATION_CONFIGURATION, 
    STATION_TRANSACTIONS, 
    STATION_ALARMES, 
    STATION_COURBES, 
    STATION_CONSIGNATION_ETAT, 
    STATION_SYNOPTIC, 
    STATION_TICKETS,
    FLOORPLAN,
    CHARGES,

    // subscription IDs
    SUBSCRIPTION_DETAIL,
    SUBSCRIPTION_STATIONS,
    SUBSCRIPTION_USERS,
    SUBSCRIPTION_VEHICLES,
    SUBSCRIPTION_EXPORTS,

    //custom
    CUSTOM_OVERALL,
    CUSTOM_PRODUCTION_N_SALES,
    CUSTOM_PRODUCTION_SITE,
    CUSTOM_STATION_FORECAST,
    CUSTOM_STATION_INDICATOR,
    CUSTOM_INDICATORS_DEFINITIONS,
    CUSTOM_STATION_LOG_BOOK,
    CUSTOM_STATION_STATS
}
