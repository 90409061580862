import { iconClass } from 'src/app/enums/icon-class.enum';
import { RouteId } from 'src/app/enums/env/routes-id-enum';
import { RouteEnv } from 'src/app/interfaces/env/RouteEnv';
import { TicketState } from 'src/swagger-gen/web/models';
import { AlarmState } from 'src/swagger-gen/web/models';

const ticketsParams = { states: TicketState.OPENED + ',' + TicketState.RE_OPENED };

export const routeEnvironment: RouteEnv[] = [
  {
    id: RouteId.DASHBOARD,
    route: 'dashboard',
    name: 'menu.dashboard',
    icon: iconClass.DASHBOARD,
    detailBackLink: null,
    enabled: true,
    tabs: [
      {
        id: RouteId.DASHDEFAULT,
        route: 'default',
        name: 'menu.dashboard',
        icon: iconClass.DASHBOARD,
        detailBackLink: RouteId.DASHBOARD,
        enabled: false,
        order: 1
      },
      {
        id: RouteId.CUSTOM_OVERALL,
        route: 'overall',
        name: 'custom.menu.overall',
        icon: iconClass.DASHBOARD,
        detailBackLink: RouteId.DASHBOARD,
        enabled: true,
        order: 2
      },
      {
        id: RouteId.CUSTOM_PRODUCTION_N_SALES,
        route: 'production',
        name: 'custom.menu.productionNsales',
        icon: iconClass.DASHBOARD,
        detailBackLink: RouteId.DASHBOARD,
        enabled: true,
        order: 3
      },
      {
        id: RouteId.CUSTOM_PRODUCTION_SITE,
        route: 'site-production',
        name: 'custom.menu.siteProduction',
        icon: iconClass.DASHBOARD,
        detailBackLink: RouteId.DASHBOARD,
        enabled: true,
        order: 4
      },
      {
        id: RouteId.DRAWGRAPHS,
        route: 'drawngraphs',
        name: 'menu.dashboardDrownGraphs',
        icon: iconClass.DASHBOARD,
        detailBackLink: RouteId.DASHBOARD,
        enabled: true,
        order: 5
      },
    ]
  },
  {
    id: RouteId.EMS,
    route: 'diagnostic',
    name: 'menu.ems',
    icon: iconClass.EMS,
    detailBackLink: RouteId.EMS,
    enabled: false
  },
  {
    id: RouteId.DASHBOARD_GNL,
    route: 'dashboard-gnl',
    name: 'menu.dashboard_gnl',
    icon: iconClass.DASHBOARD,
    detailBackLink: null,
    enabled: false
  },
  {
    id: RouteId.MAP,
    route: 'map',
    name: 'menu.map',
    icon: iconClass.MAP,
    detailBackLink: null,
    enabled: true
  },
  {
    id: RouteId.REGIONS,
    route: 'regions',
    name: 'menu.regions',
    icon: iconClass.REGIONS,
    detailBackLink: RouteId.REGIONS,
    enabled: true
  },
  {
    id: RouteId.ALARMS,
    route: 'alarms',
    name: 'menu.alarms',
    params: { states: AlarmState.RAISED },
    icon: iconClass.ALARM,
    detailBackLink: RouteId.ALARMS,
    enabled: false
  },
  {
    id: RouteId.TICKETS,
    route: 'tickets',
    name: 'menu.tickets',
    params: { states: TicketState.OPENED + ',' + TicketState.RE_OPENED },
    icon: iconClass.TICKETS,
    detailBackLink: RouteId.TICKETS,
    enabled: false
  },
  {
    id: RouteId.STATIONS,
    route: 'sites',
    name: 'menu.stations',
    icon: iconClass.STATIONS,
    detailBackLink: RouteId.REGIONS,
    enabled: true,
    tabs: [
      {
        id: RouteId.STATION_DASHBOARD,
        route: 'dashboard',
        name: 'stations.tab.dashboard',
        enabled: true,
        order: 1
      },
      {
        id: RouteId.CUSTOM_STATION_STATS,
        route: 'stats',
        name: 'custom.logbook.tab.stats',
        enabled: true,
        order: 2
      },
      {
        id: RouteId.STATION_DETAIL,
        route: 'details',
        name: 'stations.tab.detail',
        enabled: true,
        order: 3
      },
      {
        id: RouteId.STATION_TRANSACTIONS,
        route: 'transactions',
        name: 'stations.tab.charge',
        enabled: false,
        order: 5
      },
      {
        id: RouteId.STATION_TICKETS,
        route: 'tickets',
        name: 'stations.tab.tickets',
        params: { states: TicketState.OPENED +','+ TicketState.RE_OPENED }, 
        enabled: false,
        order: 9
      },
      {
        id: RouteId.STATION_ALARMES,
        route: 'alarms',
        name: 'stations.tab.alarm',
        enabled: true,
        order: 6
      },
      {
        id: RouteId.STATION_CONSIGNATION_ETAT,
        route: 'events',
        name: 'stations.tab.state_logging',
        enabled: true,
        order: 8
      },
      {
        id: RouteId.STATION_COURBES,
        route: 'graph',
        name: 'stations.tab.graph',
        enabled: true,
        order: 7
      },
      {
        id: RouteId.STATION_SYNOPTIC,
        route: 'synoptics',
        name: 'stations.tab.synoptics',
        enabled: true,
        order: 10
      },
      {
        id: RouteId.FLOORPLAN,
        route: 'floorplan',
        name: 'stations.tab.floorplan',
        enabled: false,
        order: 11
      },
      {
        id: RouteId.CUSTOM_STATION_INDICATOR,
        route: 'indicator',
        name: 'stations.tab.indicator',
        enabled: false,
        order: 13
      },
      {
        id: RouteId.CUSTOM_STATION_LOG_BOOK,
        route: 'logbook',
        name: 'stations.tab.logbook',
        enabled: true,
        order: 14
      },
      {
        id: RouteId.STATION_CONFIGURATION,
        route: 'configuration',
        name: 'stations.tab.configuration',
        enabled: true,
        order: 15
      },
    ]
  },
  {
    id: RouteId.GRAPHS,
    route: 'graphs',
    name: 'menu.graphs',
    icon: iconClass.GRAPHS,
    detailBackLink: null,
    enabled: true
  },
  {
    id: RouteId.SUBSCRIPTIONS,
    route: 'subscriptions',
    name: 'menu.subscriptions',
    icon: iconClass.SUBSCRIPTIONS,
    detailBackLink: RouteId.SUBSCRIPTIONS,
    enabled: false,
    tabs: [
      {
        id: RouteId.SUBSCRIPTION_DETAIL,
        route: 'details',
        name: 'subscriptions.tab.detail',
        enabled: false,
        order: 1
      },
      {
        id: RouteId.SUBSCRIPTION_STATIONS,
        route: 'stations',
        name: 'subscriptions.tab.stations',
        enabled: false,
        order: 2
      },
      {
        id: RouteId.SUBSCRIPTION_USERS,
        route: 'users',
        name: 'subscriptions.tab.users',
        enabled: false,
        order: 3
      },
      {
        id: RouteId.SUBSCRIPTION_VEHICLES,
        route: 'vehicles',
        name: 'subscriptions.tab.vehicles',
        enabled: false,
        order: 4
      },
      {
        id: RouteId.SUBSCRIPTION_EXPORTS,
        route: 'export',
        name: 'subscriptions.tab.export',
        enabled: false,
        order: 5
      },
    ]
  },
  {
    id: RouteId.BADGES,
    route: 'badges',
    name: 'menu.badges',
    icon: iconClass.BADGES,
    detailBackLink: RouteId.BADGES,
    enabled: false
  },
  {
    id: RouteId.USERS,
    route: 'users',
    name: 'menu.users',
    icon: iconClass.USERS,
    detailBackLink: RouteId.USERS,
    enabled: true
  },
  {
    id: RouteId.VEHICLES,
    route: 'vehicles',
    name: 'menu.vehicles',
    icon: iconClass.VEHICLES,
    detailBackLink: RouteId.VEHICLES,
    enabled: false
  },
  {
    id: RouteId.EXPORT,
    route: 'export',
    name: 'menu.export',
    detailBackLink: RouteId.EXPORT,
    enabled: false
  },
  {
    id: RouteId.ADMINISTRATION,
    route: 'admin',
    name: 'menu.admin',
    icon: iconClass.ADMINISTRATION,
    detailBackLink: RouteId.ADMINISTRATION,
    enabled: true,
    tabs: [
      {
        id: RouteId.DIFFUSION_LIST,
        route: 'table',
        name: 'menu.diffusionlists',
        enabled: false,
        order: 1
      },
      {
        id: RouteId.ROLES,
        route: 'roles',
        name: 'menu.roles',
        enabled: true,
        order: 2
      },
      {
        id: RouteId.ACQUISITION_UNIT,
        route: 'acquisition',
        name: 'menu.acquisition',
        enabled: true,
        order: 3
      },
      {
        id: RouteId.CUSTOM_INDICATORS_DEFINITIONS,
        route: 'indicators',
        name: 'custom.menu.indicatorsDefinitions',
        enabled: true,
        order: 4
      },
    ]
  },
  {
    id: RouteId.ADMINISTRATION_DETAILS,
    route: 'admin',
    name: 'menu.admin',
    icon: iconClass.SUBSCRIPTIONS,
    detailBackLink: RouteId.ADMINISTRATION_DETAILS,
    enabled: false,
    tabs: [
      {
        id: RouteId.DETAILS,
        route: 'diffusion-list',
        name: 'menu.details',
        enabled: false,
        order: 1
      },
    ]
  }
];